const Cursor = () => {

  let mouseX = 0
  let mouseY = 0
  const cursorEl = document.querySelector('.Cursor')
  const logoEl = document.querySelector('.Logo')
  const links = document.querySelectorAll('a')

  const moveCursor = event => {
    mouseX = event.pageX,
    mouseY = event.pageY - window.scrollY
    cursorEl.style.transform = `translate3d(${mouseX}px, ${mouseY}px, 0) translate(-50%, -50%)`
  }

  const hideCursor = () => {
    cursorEl.style.display = 'none'
  }

  const showCursor = () => {
    cursorEl.style.display = 'block'
  }

  const handleMouseOver = () => {
    cursorEl.style.opacity = 0.4
  }

  const handleMouseOut = () => {
    cursorEl.style.opacity = 1
  }

  const changeCursorToEagle = () => {
    cursorEl.style.width = '56px'
    cursorEl.style.height = '60px'
    cursorEl.style.backgroundColor = 'transparent'
    cursorEl.style.backgroundImage = `url('./assets/static/eagle.svg')`
  }

  const changeEagleToCursor = () => {
    cursorEl.style.width = '12px'
    cursorEl.style.height = '12px'
    cursorEl.style.backgroundColor = '#000'
    cursorEl.style.backgroundImage = 'none'
  }

  window.addEventListener('mousemove', event => {
    showCursor()
    moveCursor(event)
  })

  document.querySelector('body').addEventListener('mouseout', () => {
    hideCursor()
  })

  logoEl.addEventListener('mouseover', () => {
    changeCursorToEagle()
  })

  logoEl.addEventListener('mouseout', () => {
    changeEagleToCursor()
  })

  links.forEach(link => {
    link.addEventListener('mouseover', () => {
      handleMouseOver()
    })
    link.addEventListener('mouseout', () => {
      handleMouseOut()
    })
  })

}

export default Cursor