import Cursor from './cursor'

if (window.innerWidth > 1025) {
  Cursor()
}

if (navigator.userAgent.toLowerCase().indexOf("chrome") > -1 || navigator.userAgent.toLowerCase().indexOf("firefox") > -1) {
  window.console.log.apply(console, ["%c Design by Zunder, www.zunder.wtf %c %c🖼 %c\n", "color: #fff; background: #FD0D1B; padding:5px 0;", "color: #fff; background: #242424; padding:8px 0 8px 8px;", "background: #242424; padding:8px 0", "background: #242424; padding:8px 8px 8px 0"])
  window.console.log.apply(console, ["%c Code by Christian Laufenböck, www.laufenboeck.com %c %c💻 %c\n", "color: #fff; background: #FD0D1B; padding:8px 0;", "color: #fff; background: #242424; padding:8px 0 8px 8px;", "background: #242424; padding:8px 0", "background: #242424; padding:8px 8px 8px 0"])
} else {
  window.console && window.console.log("Design by Zunder, www.zunder.wtf");
  window.console && window.console.log("Code by Christian Laufenböck, www.laufenboeck.com");
}